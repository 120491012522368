<template>
  <div class="ticket-paid">
    <Heading level="4">Поточне авто</Heading>
    <CurrentAuto
      class="tick-pay-choice__title"
      :current-auto="currentAutoTicket"
      camera-status="ticket_found"
      :auto-plate-number="currentAutoTicket.auto_registration_plate"
      :country-auto-registration-plate="currentAutoTicket.country_registration"
    />

    <div class="ticket-paid__succsess">
      <Heading level="5" class="ticket-paid__subtitle">
        <svg-icon name="icon-success"></svg-icon>
        {{ $t('ticket_paid.succses_payment') }}
      </Heading>
      <div class="ticket-paid__number">
        <Heading level="3" class="ticket-paid__number-text">
          {{ $t('ticket_paid.ticket_number') }}
          <span class="ticket-paid__number-queue">
            {{ currentAutoTicket.id }}
          </span>
        </Heading>
        <div class="ticket-paid__btns">
          <ButtonBase color="white" size="md-bg">
            {{ $t('ticket_paid.print_check') }}
          </ButtonBase>
          <ButtonBase color="blue" size="md-bg" @click.native="goToDashboard">
            {{ $t('ticket_paid.ready') }}
          </ButtonBase>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFromLS, removeFromLS } from '@/library/helpers';

import ButtonBase from '@/elements/Buttons/ButtonBase.vue';
import Heading from '@/elements/Heading/Heading.vue';
import CurrentAuto from '@/components/CurrentAuto/CurrentAuto.vue';

export default {
  components: { CurrentAuto, Heading, ButtonBase },
  data() {
    return {
      currentAutoTicket: getFromLS('new-ticket'),
    };
  },
  methods: {
    goToDashboard() {
      removeFromLS('new-ticket');

      this.$router.push({
        name: 'dashboard',
      });
    },
  },
};
</script>
<style lang="sass">
.ticket-paid__succsess
  display: flex
  flex-direction: column
  align-items: center
  border-top: 1px solid var(--primary-color-light-grey-2)

.ticket-paid__number
  text-align: center
  margin-bottom: 65px

.ticket-paid__number-text
  font-size: 48px
  line-height: 32px
  margin-top: 0!important
  margin-bottom: 52px
  font-weight: 700

.ticket-paid__number-queue
  color: var(--btn-color-blue)

.ticket-paid__btns
  display: flex
  gap: 32px
</style>
